import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import call from '../../service';
import { FileInput } from '../../utils/FileInput';
import avatarUrl from '../../utils/makeAvatarUrl';
import MultipleSelect from '../../utils/MultipleSelect';
import { astrix, ClearCache, convertImageToPdf, dataURItoBlob, extractFloatFromText, GetCache, getDocDetails, handleFileCommonFunction, handleFileWithAsyncAwait, most_used_currencies, multiSelectEventHandler, SetCache, toolTip } from '../../utils/myFunctions';
import { InputForTable, InputWithSelect, NewInput, NewSelect, NewTextArea, SelectForTable } from '../../utils/newInput';
import { NewTable } from '../../utils/newTable';
import { StarRating } from '../../utils/starRating';
import toastDisplay from '../../utils/toastNotification';
import Header from '../partial/header';
import HeaderV2 from '../partial/headerV2';
import Sidebar, { traderTypeIds } from '../partial/sidebar';
import SideBarV2 from '../partial/sideBarV2';
import { PopupMessage } from '../popupMessage';
import config from '../../config.json';
import moment from 'moment';
import TutorialPopup, { TutorialVideoPopup } from '../tutorialPopup';
import ChatBoxPopUp2 from '../chatRoom/components/ChatBoxPopUp2';
import Filter from '../InvoiceDiscounting/components/Filter';
import NewTablev2, { showGridCol } from '../../utils/newTablev2';
import Pagination from '../InvoiceDiscounting/contract/components/pagination';
import { bgQuotesColumn, wcQuotesColumn } from '../Dashboard/TableJson';
import { Action } from '../myCounterPartComp/action';
import { FormProgressBar } from '../CommonComponent/FormProgressBar';
import MultipleSelectForTable from '../../utils/MultipleSelectForTable';
import FinanceInvoiceModal from '../InvoiceDiscounting/contract/components/financeinvoiceModal';

const finHighlights = [
  { name: 'Selected', color: '#5CB8D3' },
  { name: 'Quote received', color: '#2ECC71' },
  { name: 'Rejected', color: '#FE4141' }
]

const Voucher = ({ userTokenDetails, navToggleState }) => {

  const queryParams = new URLSearchParams(window.location.search)
  let serarchParam = queryParams.get('search')

  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [tableData, setTableData] = useState([])

  const [showAddBuyerForm, toggleAddBuyerForm] = useState(false)
  const [tab, setTab] = useState(0)
  const [data, setData] = useState({ totalAmtCurrency: 'USD' })
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)

  const [dunsData, setDunsData] = useState([])
  const [hsnCodes, setHsnCodes] = useState([])
  const [countrys, setCountrys] = useState([])
  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [financierData, setFinancierData] = useState([]);
  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10, search: serarchParam ? serarchParam : '' })
  const [lcTutorial, toggleLCTutorial] = useState(localStorage.getItem("lcTutorialStep") == 1)
  const [tutorialStage, setTutorialStage] = useState(0)
  const [showTutorialVideo, toggleTutorialVideo] = useState({ show: false, link: null })
  const [filterData, setFilterData] = useState(GetCache("accountingGroupsFilterData"))
  const [createGroupView, toggleCreateGroupView] = useState({ show: false })
  const [voucherUploadPopup, setVoucherUploadPopup] = useState({ show: false })
  const [itemsNeedToSave, setItemsNeedToSave] = useState([])

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [action, setAction] = useState({ show: false, index: null })
  const [ledgers, setLedgers] = useState([])
  const [invItems, setInvItems] = useState([null])
  const [items, setItems] = useState([])
  const [particulars, setParticulars] = useState([null, null])
  const [salesPurchaseVouchers, setSalesPurchaseVouchers] = useState([])
  const [journalInvItems, setJournalInvItems] = useState({})

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  let jj = -1
  let kk = -1




  console.log(
    userTokenDetails.UserAccessPermission,
    JSON.parse(userTokenDetails.UserAccessPermission),
    "this is exportermanagement--->>>>>"
  );
  const [userPermissions, setUserPermissions] = useState(null);

  useEffect(() => {
    if (userTokenDetails.UserAccessPermission) {
      try {
        const parsedPermissions = JSON.parse(userTokenDetails.UserAccessPermission);
        setUserPermissions(parsedPermissions);
      } catch (error) {
        console.error("Failed to parse user permissions:", error);
      }
    }
  }, [userTokenDetails.UserAccessPermission]);

  console.log(userPermissions, "Parsed user permissions");

  const getSubPermissions = (componentPath) => {
    if (!userPermissions) return [];

    for (const role of userPermissions) {
      for (const perm of role.permissions) {
        if (perm.item === componentPath) {
          return perm.selected;
        }
      }
    }
    return [];
  };

  const subPermissions = getSubPermissions("tallyTxnsVoucher");
  console.log(subPermissions, "this is subpermission--//////");

  // Boolean states for permissions
  const [booladd, setbooladd] = useState(false);
  const [boolview, setboolview] = useState(false);

  useEffect(() => {
    const hasAddPermission = subPermissions.includes("create");
    const hasViewPermission = subPermissions.includes("view");
    console.log(hasAddPermission, "this is add permission--->>>>>");

    // Update state only if there is a change
    if (booladd !== hasAddPermission) {
      setbooladd(hasAddPermission);
    }
    if (boolview !== hasViewPermission) {
      setboolview(hasViewPermission);
    }
  }, [subPermissions, booladd, boolview]);

  console.log(subPermissions, "this is buyer management--->>>>>");
  console.log(booladd, "this is bool add--->>>>");


  useEffect(() => {
    call('POST', 'getTransactionVoucher', { userId, voucherType: ["Sales", "Purchase"] }).then(res => {
      let temp = []
      for (let index = 0; index < res.data.length; index++) {
        const element = res.data[index];
        if ((element.details?.voucherType === "Sales" || element.details?.voucherType === "Purchase") && element.details.invoiceNo) {
          temp.push({ name: element.details.invoiceNo })
        }
      }
      setSalesPurchaseVouchers([...temp])
    })
  }, [])

  useEffect(() => {
    setshowLoader(true)
    let objectAPI = {
      "userId": userId,
      "userEmail": userEmail,
      "userTypeId": userTypeId,
      "type": 'all',
      currentPage: page,
      ...filter
    }
    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push((element.accordianId === "status" || element.accordianId === "financiersFilter" || element.accordianId === "timeLeft") ? i[element["labelName"]] : `'${i[element["labelName"]]}'`)
            }
          })
        }
        else if (element.type === "minMaxDate") {
          objectAPI[element.accordianId] = element["value"]
        }
      }
    }
    call('POST', 'getTransactionVoucher', objectAPI).then((result) => {
      console.log('running getTransactionVoucher api-->', result);
      setDbData(result.data);
      setCount(result.countData);
      setshowLoader(false)
    }).catch((e) => {
      // console.log('error in getTransactionVoucher', e);
      setshowLoader(false)
    });
    SetCache("voucherTxnsFilterData", filterData)
  }, [refresh, page, filterData])

  useEffect(() => {
    call('POST', 'getusercommoditylist', { userId }).then(res => {
      let tempItems = [{ commodity_pretty_name: 'Add New' }]
      tempItems = tempItems.concat(res?.length ? res : [])
      setItems([...tempItems])
    })
  }, [])

  useEffect(() => {
    call('POST', 'getAccountingLedgers', { userId }).then(res => {
      for (let index = 0; index < res.data.length; index++) {
        const element = res.data[index];
        res.data[index]["name"] = element.details.name
      }
      let tempLedgers = [{ name: 'Add New' }]
      tempLedgers = tempLedgers.concat(res?.data?.length ? res.data : [])
      setLedgers([...tempLedgers])
    })
  }, [refresh])

  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    formdata.append('wcApplicationId', selectedChat.wcApplicationId)
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))
    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }

  const handleChange = async (event) => {
    event.persist()
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }

  async function handleValidation() {
    let validateFields = ["voucherType"]
    let err = {}
    if (data.voucherType === 'Journal') {
      validateFields = ["voucherType", "date", "totalAmtCurrency", "totalDebit", "totalCredit"]
      let isParticularAdded = false
      if (data[`particularName0`] && data[`particularAmount0`] && data[`particularName1`] && data[`particularAmount1`]) {
        isParticularAdded = true
      }
      if (!isParticularAdded) {
        err["particular"] = 'Mandatory'
        toastDisplay('Enter particulars to save', "info")
      }
    }
    if (data.voucherType === 'Sales' || data.voucherType === "Purchase") {
      validateFields = ["voucherType", "date", "partyName", "selectedLedger", "placeOfSupply", "totalAmount", "totalAmtCurrency"]
      // check if OCR items if any pushed into db or not start
      let allItemsAreSaved = true
      for (let index = 0; index < itemsNeedToSave.length; index++) {
        const element = itemsNeedToSave[index];
        if (element) {
          allItemsAreSaved = false
        }
      }
      if (!allItemsAreSaved) {
        err[`allItemsAreSaved`] = 'Save all items to inventory first'
        toastDisplay(err[`allItemsAreSaved`], "error")
      }
      // check if OCR items if any pushed into db or not end
    }
    if (data.voucherType === 'Credit Note' || data.voucherType === "Debit Note") {
      validateFields = ["voucherType", "date", "partyName", "selectedLedger", "totalAmount", "totalAmtCurrency"]
    }
    if (data.voucherType === 'Payment' || data.voucherType === "Receipt") {
      validateFields = ["voucherType", "date", "selectedLedger", "totalAmount", "totalAmtCurrency"]
      let isParticularAdded = false
      for (let index = 0; index < particulars.length; index++) {
        if (particulars[index] === null && data[`particularName${index}`] && data[`particularAmount${index}`]) {
          isParticularAdded = true
        }
      }
      if (!isParticularAdded) {
        err["particular"] = 'Mandatory'
        toastDisplay('Enter particulars to save', "info")
      }
    }
    for (let index = 0; index < validateFields.length; index++) {
      const element = validateFields[index];
      if (!data[element]) {
        err[element] = 'Mandatory field'
      }
    }
    if (!Object.keys(err).length) {
      setshowLoader(true)
      if ((data.voucherType === "Sales" || data.voucherType === "Purchase") && !data.id) {
        for (let index = 0; index < invItems.length; index++) {
          if (invItems[index] === null && data[`itemName${index}`]) {
            await call("POST", 'adjustQuantityTrail', {
              commodityPrettyName: data[`itemName${index}`], userId,
              rate: data[`itemRate${index}`], usedFor: data.invoiceNo || "NA", date: data.date,
              quantity: data.voucherType === "Sales" ? data[`itemQuantity${index}`] * -1 : data[`itemQuantity${index}`]
            })
          }
        }
      }
      call('POST', 'saveTransactionVoucher', { ...data, userId, invItems, particulars, journalInvItems }).then(res => {
        toastDisplay(data.id ? "Voucher Updated" : "Voucher Created", "success", () => {
          setshowLoader(false)
          window.location.reload()
          // toggleCreateGroupView({ show: false })
          // setData({})
          // setRefresh(refresh + 1)
        })
      })
    }
    setErrors({ ...err })
  }

  async function processJSONFileObj() {
    setshowLoader(true)
    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        let json = JSON.parse(e.target.result);
        json = json?.Voucher
        if (json.length) {
          for (let index = 0; index < json.length; index++) {
            const element = json[index];
            let ObjectToPush = {}
            let invoiceItems = []
            let particularsList = []
            if (element?.VoucherTypeName === "Sales" || element?.VoucherTypeName === "Purchase") {
              ObjectToPush = element
              ObjectToPush["voucherType"] = element?.VoucherTypeName
              ObjectToPush["invoiceNo"] = element?.Reference
              ObjectToPush["date"] = moment(element?.Date).format("YYYY-MM-DD")
              ObjectToPush["partyName"] = element?.PartyLedgerName
              ObjectToPush["narration"] = element?.Narration
              ObjectToPush["totalAmount"] = Math.abs(element?.Amount || 0)
              ObjectToPush["totalAmtCurrency"] = most_used_currencies.filter(i => {
                if (i.symbol === element?.Currency) { return true }
              })?.[0]?.code || "USD"
              ObjectToPush["selectedLedger"] = element?.InventoryEntries?.[0]?.AccLedger
              for (let j = 0; j < element?.InventoryEntries?.length; j++) {
                const itemDetails = element?.InventoryEntries[j];
                invoiceItems.push(null)
                ObjectToPush[`itemName${j}`] = itemDetails?.StockItemName
                ObjectToPush[`itemGodown${j}`] = itemDetails?.GodownName
                ObjectToPush[`itemQuantity${j}`] = itemDetails?.Quantity
                ObjectToPush[`itemRate${j}`] = itemDetails?.Rate
                ObjectToPush[`itemAmt${j}`] = Math.abs(itemDetails?.Amount)
              }
            }
            else if (element?.VoucherTypeName === "Payment") {
              ObjectToPush = element
              ObjectToPush["voucherType"] = element?.VoucherTypeName
              ObjectToPush["invoiceNo"] = element?.Reference
              ObjectToPush["date"] = moment(element?.Date).format("YYYY-MM-DD")
              ObjectToPush["selectedLedger"] = element?.LedgerEntries?.[element?.LedgerEntries.length - 1]?.LedgerName
              ObjectToPush["totalAmount"] = Math.abs(element?.Amount || 0)
              ObjectToPush["totalAmtCurrency"] = most_used_currencies.filter(i => {
                if (i.symbol === element?.Currency) { return true }
              })?.[0]?.code || "USD"
              ObjectToPush["narration"] = element?.Narration
              for (let index = 0; index < element.LedgerEntries.length; index++) {
                const element2 = element.LedgerEntries[index];
                if (element2.Amount <= 0) {
                  if (element2?.BillAllocations?.length) {
                    for (let j = 0; j < element2?.BillAllocations?.length; j++) {
                      particularsList.push(null)
                      let billAllocations = element2?.BillAllocations[j]
                      ObjectToPush[`particularName${particularsList.length - 1}`] = element2.LedgerName
                      ObjectToPush[`referenceType${particularsList.length - 1}`] = billAllocations.BillType
                      ObjectToPush[`referenceName${particularsList.length - 1}`] = billAllocations.Name
                      ObjectToPush[`particularAmount${particularsList.length - 1}`] = Math.abs(billAllocations.Amount || 0)
                    }
                  }
                  else {
                    particularsList.push(null)
                    ObjectToPush[`particularName${particularsList.length - 1}`] = element2.LedgerName
                    ObjectToPush[`particularAmount${particularsList.length - 1}`] = Math.abs(element2.Amount || 0)
                  }
                }
              }
            }
            else if (element?.VoucherTypeName === "Receipt") {
              ObjectToPush = element
              ObjectToPush["voucherType"] = element?.VoucherTypeName
              ObjectToPush["invoiceNo"] = element?.Reference
              ObjectToPush["date"] = moment(element?.Date).format("YYYY-MM-DD")
              ObjectToPush["selectedLedger"] = element?.LedgerEntries?.[element?.LedgerEntries.length - 1]?.LedgerName
              ObjectToPush["totalAmount"] = Math.abs(element?.Amount || 0)
              ObjectToPush["totalAmtCurrency"] = most_used_currencies.filter(i => {
                if (i.symbol === element?.Currency) { return true }
              })?.[0]?.code || "USD"
              ObjectToPush["narration"] = element?.Narration
              for (let index = 0; index < element.LedgerEntries.length; index++) {
                const element2 = element.LedgerEntries[index];
                if (element2.Amount >= 0) {
                  if (element2?.BillAllocations?.length) {
                    for (let j = 0; j < element2?.BillAllocations?.length; j++) {
                      particularsList.push(null)
                      let billAllocations = element2?.BillAllocations[j]
                      ObjectToPush[`particularName${particularsList.length - 1}`] = element2.LedgerName
                      ObjectToPush[`referenceType${particularsList.length - 1}`] = billAllocations.BillType
                      ObjectToPush[`referenceName${particularsList.length - 1}`] = billAllocations.Name
                      ObjectToPush[`particularAmount${particularsList.length - 1}`] = Math.abs(billAllocations.Amount || 0)
                    }
                  }
                  else {
                    particularsList.push(null)
                    ObjectToPush[`particularName${particularsList.length - 1}`] = element2.LedgerName
                    ObjectToPush[`particularAmount${particularsList.length - 1}`] = Math.abs(element2.Amount || 0)
                  }
                }
              }
            }
            try {
              if (Object.keys(ObjectToPush).length) {
                await call('POST', 'saveTransactionVoucher', { ...ObjectToPush, userId, invItems: invoiceItems, particulars: particularsList })
              }
            } catch (error) {
              console.log("errorInsaveTransactionVoucher", error);
            }
          }
          toastDisplay('Data uploaded successfully', "success", () => {
            window.location.reload()
          })
        }
        else {
          toastDisplay('Data not found', "error")
        }
        setshowLoader(false)
      } catch (error) {
        console.error('ErrorInprocessJSONFileObj', error);
        toastDisplay('Something went wrong', "error")
        setshowLoader(false)
      }
    };
    reader.readAsText(data.tallyVoucherJsonFile);
  }

  async function invoiceOCRInit(pdfFileObj) {
    if (pdfFileObj?.name) {
      setshowLoader(true)
      let formData = new FormData()
      formData.append("document", pdfFileObj)
      let resp = await call('POST', 'analyzeInvoiceByOCR', formData)
      // console.log("invoiceOCRResp==========>", resp);
      let counterPartyName = data?.voucherType === "Sales" ? resp?.customer_name : resp?.supplier_name
      let itemDetailsFromOCR = resp?.complete_response?.items?.length ? resp?.complete_response?.items : []
      let tempItemDetailsNeedToSave = []
      let tempData = {}
      let tempInvItems = []
      let tempItems = items
      for (let index = 0; index < itemDetailsFromOCR.length; index++) {
        let element = itemDetailsFromOCR[index];
        tempData[`itemName${index}`] = element.product_description || undefined
        tempData[`itemQuantity${index}`] = isNaN(element.product_quantity) ? extractFloatFromText(element.product_quantity) : element.product_quantity
        tempData[`itemRate${index}`] = isNaN(element.product_per_unit_price) ? extractFloatFromText(element.product_per_unit_price) : element.product_per_unit_price
        tempData[`itemAmt${index}`] = isNaN(element.product_total_amount) ? extractFloatFromText(element.product_total_amount) : element.product_total_amount
        let isExistAlredy = null
        if (element?.product_description) {
          // check if this item alredy exists in db
          isExistAlredy = items.filter(i => {
            if (i?.commodity_pretty_name?.toLowerCase() === element?.product_description?.toLowerCase()) {
              return true
            }
          })?.[0]
          if (!isExistAlredy?.commodity_pretty_name) {
            tempItemDetailsNeedToSave[index] = element.product_description
          }
        }
        if (tempData[`itemName${index}`]) {
          tempInvItems.push(null)
          if (!isExistAlredy?.commodity_pretty_name) {
            tempItems.push({ commodity_pretty_name: tempData[`itemName${index}`] })
          }
        }
      }
      // console.log("ttttttttttttttttttt", itemDetailsFromOCR, tempItems, tempInvItems, tempItemDetailsNeedToSave, tempData);
      setItems([...tempItems])
      setInvItems([...tempInvItems])
      setItemsNeedToSave([...tempItemDetailsNeedToSave])
      setData({
        ...data, invoiceNo: resp?.complete_response?.invoice_no, totalAmtCurrency: resp?.invoice_currency_code,
        totalAmount: extractFloatFromText(resp?.invoice_amount || "0"),
        partyName: counterPartyName, ...tempData
      })
      if (counterPartyName) {
        let isLedgerExists = ledgers.filter(i => {
          if (i.name === counterPartyName) { return true }
        })?.[0]
        if (!isLedgerExists) {
          call('POST', 'saveAccountingMastersLedger', {
            name: counterPartyName, alias: counterPartyName, parent: 'Primary', userId
          })
          setRefresh(refresh + 1)
        }
      }
      setshowLoader(false)
    }
  }

  const handleMessage = (event) => {
    if (event.origin !== window.location.origin) {
      return;
    }
    // console.log('Message received:', event.data, itemsNeedToSave);
    if (event.data) {
      let tempItemDetailsNeedToSave = itemsNeedToSave
      for (let index = 0; index < itemsNeedToSave.length; index++) {
        const element = itemsNeedToSave[index];
        if (element && element?.toLowerCase() === event.data) {
          tempItemDetailsNeedToSave[index] = undefined
        }
      }
      setItemsNeedToSave([...tempItemDetailsNeedToSave])
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [itemsNeedToSave]);


  const handleCreateNew = () => {

    if (booladd || userPermissions == null) {
      toggleCreateGroupView({ show: true })
    } else {
      console.log("toast info disp--?????")
      toastDisplay("Permission Denied, Ask Owner for permission.", "info");
    }
  }

  // console.log("invItem111111111111111", items);



  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      {selectedChat.isChatOpen &&
        <div className="chatboxDivFixed">
          <ChatBoxPopUp2
            chatList={chatList}
            user_avatar={selectedChat.logo}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            parties={selectedChat.parties}
            userTokenDetails={userTokenDetails}
            onChatSend={(file, parties) => sendChatMsg(file, parties)}
            message={message}
            setTextMsg={setTextMsg}
            reloadChatList={() => getChatDetails({
              chat_room_id: selectedChat.chatRoomId,
              loggedInUser: userId
            })}
            onPopupClose={() => {
              setSelectedChat({
                receiverName: '',
                receiverId: '',
                userId: userId,
                isChatOpen: false,
                logo: ''
              })
              setRefresh(refresh + 1)
            }}
          />
        </div>
      }
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="tallyTxnsVoucher" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={`Tally > Transactions > Voucher ${createGroupView.show ? `${createGroupView.viewUpdate ? ' > View/Update' : ' > Create New'}` : ''}`}
              userTokenDetails={userTokenDetails} />

            {voucherUploadPopup.show && (
              <FinanceInvoiceModal
                limitinvoice={voucherUploadPopup.show}
                setLimitinvoice={() => { setVoucherUploadPopup({ show: false }) }}
                closeSuccess={() => { setVoucherUploadPopup({ show: false }) }}
              >
                <label className="text-center font-size-16 font-wt-600 w-100">Import From Tally</label>
                <label className="text-center font-size-13 font-wt-600 w-100 mb-4">Please upload the JSON file exported using the tally TCP file.</label>
                <div className="d-flex justify-content-center">
                  <div className='col-10'>
                    <FileInput isEditable={true} name={"tallyVoucherJsonFile"} value={data.tallyVoucherJsonFile}
                      onChange={e => handleFileCommonFunction(e, data, errors, setData, setErrors, setshowLoader, ["json"])}
                      onUploadCancel={() => setData({ ...data, [`tallyVoucherJsonFile`]: null })}
                    />
                    {errors[`tallyVoucherJsonFile`] ? <div class="text-danger mt-2 font-size-12">
                      <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                      <b>{errors[`tallyVoucherJsonFile`]}</b></div> : ''}
                    <div className='d-flex justify-content-center mt-4'>
                      <button disabled={!data.tallyVoucherJsonFile?.name} className={`new-btn w-60 py-2 px-2 text-white cursor`}
                        onClick={processJSONFileObj}
                      >Submit</button>
                    </div>
                  </div>
                </div>
              </FinanceInvoiceModal>
            )}

            <div className='mt-4' style={{}} >
              {createGroupView.show ? null : <div className='filter-div position-relative'>
                <Filter
                  filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                  showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh}
                  isAdditionalButton>
                  <div className="d-flex gap-3">
                    {/* <div>
                      <button className={`new-btn  py-2 px-2 text-white cursor`} onClick={() => setVoucherUploadPopup({ show: true })}>Import From Tally</button>
                    </div> */}
                    <div>
                      <button className={`new-btn  py-2 px-2 text-white cursor`} onClick={handleCreateNew}>Create New</button>
                    </div></div>
                </Filter>
              </div>}

              {createGroupView.show ?
                <>
                  <i
                    onClick={() => {
                      window.location.reload()
                    }}
                    class="fas fa-2x fa-arrow-left mx-1 mb-4 icon-color cursor"></i>
                  <div className="bg-white pb-2">
                    <div className="d-flex justify-content-center pt-5 mb-1" >
                      <FormProgressBar
                        tabs={
                          [{ name: "Voucher Details" }]} activeTab={tab} label={"name"}
                        separationWidth={'1rem'}
                      />
                    </div>
                    {!data.id && (data.voucherType === "Sales" || data.voucherType === "Purchase") ?
                      <div className='d-flex row mt-5 mb-4 mx-0 col-8'>
                        <label className='font-size-14 font-wt-500' >{`Upload ${data.voucherType} invoice & our system will fetch details from document automatically`}</label>
                        <div className='col-8'>
                          <FileInput
                            name={`invoiceDocForOCR`} value={data[`invoiceDocForOCR`]} error={errors[`invoiceDocForOCR`]}
                            isEditable={true} onChange={async (e) => {
                              setshowLoader(true)
                              let fileData = await handleFileWithAsyncAwait(e)
                              // console.log("fileDataaaaaaaaaaaaaaa", fileData);
                              if (fileData.status) {
                                setErrors({ ...errors, invoiceDocForOCR: "" })
                                setData({ ...data, invoiceDocForOCR: fileData.msg?.invoiceDocForOCR })
                                invoiceOCRInit(fileData.msg?.invoiceDocForOCR)
                              }
                              else {
                                setErrors({ ...errors, invoiceDocForOCR: fileData.msg?.invoiceDocForOCR })
                                setshowLoader(false)
                              }
                            }}
                            onUploadCancel={() => setData({ ...data, [`invoiceDocForOCR`]: null })}
                          />
                          {errors[`invoiceDocForOCR`] ? <div class="text-danger mt-2 font-size-12">
                            <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                            <b>{errors[`invoiceDocForOCR`]}</b></div> : ''}
                        </div>
                      </div> : null}
                    <div className="row d-flex p-4">
                      <div className="col-md-3">
                        <MultipleSelect singleSelection
                          Label={'Voucher Type'}
                          Id={`voucherType`}
                          isAstrix
                          optiondata={[{ name: 'Purchase' }, { name: 'Sales' }, { name: 'Payment' }, { name: 'Receipt' }, { name: 'Credit Note' }, { name: 'Debit Note' }, { name: 'Journal' }]}
                          onChange={(e) => {
                            handleChange(multiSelectEventHandler(e, `voucherType`, "name"))
                          }}
                          value={data[`voucherType`] ? [data[`voucherType`]] : []}
                          name={`voucherType`}
                          labelKey={"name"}
                          valKey={"name"}
                          error={errors[`voucherType`]}
                        />
                      </div>
                      <div className="col-md-3">
                        <NewInput label={"Invoice/Ref No"}
                          name={"invoiceNo"} value={data.invoiceNo} error={errors.invoiceNo}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-3">
                        <NewInput type={"date"} label={"Date"} isAstrix
                          name={"date"} value={data.date} error={errors.date}
                          onChange={handleChange} />
                      </div>
                      {(data.voucherType === "Sales" || data.voucherType === "Purchase" || data.voucherType === "Credit Note" || data.voucherType === "Debit Note") ? <div className="col-md-3">
                        <MultipleSelect singleSelection
                          Label={'Select Party'}
                          Id={`partyName`}
                          isAstrix
                          onClick={() => { setRefresh(refresh + 1) }}
                          optiondata={ledgers}
                          onChange={(e) => {
                            if (e?.[e?.length - 1]?.name === "Add New") {
                              window.open(`/tallyMastersAccountingLedger?addNew=true`, "_blank")
                            }
                            else {
                              handleChange(multiSelectEventHandler(e, `partyName`, "name"))
                            }
                          }}
                          value={data[`partyName`] ? [data[`partyName`]] : []}
                          name={`partyName`}
                          labelKey={"name"}
                          valKey={"name"}
                          error={errors[`partyName`]}
                        />
                      </div> : null}

                      {data.voucherType != "Journal" ?
                        <div className="col-md-3">
                          <MultipleSelect singleSelection
                            Label={(data.voucherType === "Credit Note" || data.voucherType === 'Debit Note') ? "Select Ledger" : data.voucherType === "Purchase" ? 'Purchase Ledger' :
                              data.voucherType === "Sales" ? "Sales Ledger" :
                                data.voucherType === "Payment" ? "Select Account" : data.voucherType === "Receipt" ? "Select Account" : ""}
                            Id={`selectedLedger`}
                            isAstrix
                            optiondata={ledgers}
                            onClick={() => { setRefresh(refresh + 1) }}
                            onChange={(e) => {
                              if (e?.[e?.length - 1]?.name === "Add New") {
                                window.open(`/tallyMastersAccountingLedger?addNew=true`, "_blank")
                              }
                              else {
                                handleChange(multiSelectEventHandler(e, `selectedLedger`, "name"))
                              }
                            }}
                            value={data[`selectedLedger`] ? [data[`selectedLedger`]] : []}
                            name={`selectedLedger`}
                            labelKey={"name"}
                            valKey={"name"}
                            error={errors[`selectedLedger`]}
                          />
                        </div> : null}
                      {(data.voucherType === "Purchase" || data.voucherType === "Sales") ? <div className="col-md-3">
                        <NewInput label={"Place of Supply"}
                          name={"placeOfSupply"} value={data.placeOfSupply} error={errors.placeOfSupply}
                          onChange={handleChange} />
                      </div> : (data.voucherType === "Payment" || data.voucherType === "Receipt") ? <>
                        <div className="col-md-3">
                          <NewInput label={"Transaction Date"} type={"date"}
                            name={"transactionDate"} value={data.transactionDate} error={errors.transactionDate}
                            onChange={handleChange} />
                        </div>
                        <div className="col-md-3">
                          <NewSelect label={"Transaction Type"} selectData={[{ name: 'Cash' }, { name: 'Bank Transfer' }]}
                            name={"transactionType"} value={data.transactionType} error={errors.transactionType} optionLabel={"name"}
                            optionValue={"name"}
                            onChange={handleChange} />
                        </div>
                        {data.transactionType === "Bank Transfer" ? <>
                          <div className="col-md-3">
                            <NewSelect label={"Transfer Mode"} selectData={[{ name: 'Cheque' }, { name: 'DD' }, { name: 'NEFT' }, { name: 'RTGS' }, { name: 'IMPS' }, { name: 'UPI' },
                            { name: 'OTHER' }
                            ]} optionLabel={"name"} optionValue={"name"}
                              name={"transferMode"} value={data.transferMode} error={errors.transferMode}
                              onChange={handleChange} />
                          </div>
                          <div className="col-md-3">
                            <NewInput label={"Transaction No"} name={"transactionNo"} value={data.transactionNo}
                              error={errors.transactionNo} onChange={handleChange} />
                          </div>
                        </> : null}
                      </> : null}
                    </div>

                    {data.voucherType != "Journal" ? <>
                      {(data.voucherType === 'Purchase' || data.voucherType === "Sales" || data.voucherType === "Credit Note" || data.voucherType === "Debit Note") ? <><label className='font-size-14 font-wt-500 w-100 px-4 mb-3' >Item Details</label>
                        <div class="d-flex mx-4">
                          <NewTablev2 columns={[{ subColumns: 'Item No', subColumnStyle: { width: '4%' } }, { subColumns: 'Item Name', subColumnStyle: { width: '25%' } }, { subColumns: 'Godown', subColumnStyle: { width: '10%' } },
                          { subColumns: 'Quantity', subColumnStyle: { width: '5%' } }, { subColumns: 'Rate', subColumnStyle: { width: '5%' } }, { subColumns: 'Amount', subColumnStyle: { width: '5%' } },
                          { subColumns: 'Action', subColumnStyle: { width: '5%' } }]} >
                            {invItems.map((i, j) => {
                              if (i === null) {
                                jj = jj + 1
                                return <tr>
                                  <td><label className="font-size-14 font-wt-400 w-100 text-break" >{`${jj + 1}`}</label></td>
                                  <td>
                                    <MultipleSelectForTable singleSelection
                                      Label={'Select'}
                                      Id={`itemName${j}`}
                                      optiondata={items}
                                      onClick={() => {
                                        call('POST', 'getusercommoditylist', { userId }).then(res => {
                                          let tempItems = [{ commodity_pretty_name: 'Add New' }]
                                          tempItems = tempItems.concat(res?.length ? res : [])
                                          setItems([...tempItems])
                                        })
                                      }}
                                      onChange={(e) => {
                                        if (e?.[e?.length - 1]?.commodity_pretty_name === "Add New") {
                                          window.open(`/manageCommodities?add=true`, "_blank")
                                        }
                                        else {
                                          handleChange(multiSelectEventHandler(e, `itemName${j}`, "commodity_pretty_name"))
                                        }
                                      }}
                                      value={data[`itemName${j}`] ? [data[`itemName${j}`]] : []}
                                      name={`itemName${j}`}
                                      labelKey={"commodity_pretty_name"}
                                      valKey={"commodity_pretty_name"}
                                      error={errors[`itemName${j}`]}
                                    /></td>
                                  <td>
                                    <MultipleSelectForTable singleSelection
                                      Label={'Select'}
                                      Id={`itemGodown${j}`}
                                      optiondata={items?.filter((m, n) => { if (m.commodity_pretty_name === data[`itemName${j}`]) { return true } })?.[0]?.procured?.filter((m, n) => {
                                        if (m.state === 'Procured' && m.godown) { return true }
                                      }) || []}
                                      onChange={(e) => {
                                        handleChange(multiSelectEventHandler(e, `itemGodown${j}`, "godown"))
                                      }}
                                      value={data[`itemGodown${j}`] ? [data[`itemGodown${j}`]] : []}
                                      name={`itemGodown${j}`}
                                      labelKey={"godown"}
                                      valKey={"godown"}
                                      error={errors[`itemGodown${j}`]}
                                    /></td>
                                  <td>
                                    <InputForTable
                                      type={"number"} name={`itemQuantity${j}`} value={data[`itemQuantity${j}`]} error={errors[`itemQuantity${j}`]} onChange={handleChange} />
                                  </td>
                                  <td>
                                    <InputForTable
                                      type={"number"} name={`itemRate${j}`} value={data[`itemRate${j}`]} error={errors[`itemRate${j}`]} onChange={handleChange} />
                                  </td>
                                  <td>
                                    <InputForTable type={"number"} name={`itemAmt${j}`} value={data[`itemAmt${j}`]} error={errors[`itemAmt${j}`]} onChange={handleChange} />
                                  </td>
                                  <td>
                                    <div className="" >
                                      {(invItems.length - invItems.filter(i => {
                                        if (i === undefined) {
                                          return true
                                        }
                                      }).length) - 1 === jj ?
                                        <img
                                          onClick={async () => {
                                            let temp = invItems
                                            temp.push(null)
                                            setInvItems([...temp])
                                          }}
                                          className="cursor" src="assets/images/add_black_icon.png" /> :
                                        <img
                                          onClick={() => {
                                            let temp = invItems
                                            temp[j] = undefined
                                            setInvItems([...temp])
                                          }}
                                          className="cursor" src="assets/images/delete.png" />}
                                      {itemsNeedToSave[j] ?
                                        <label onClick={() => {
                                          window.open(`/manageCommodities?add=true&closeAfter=true&itemNameToSave=${data[`itemName${j}`]}`, "_blank")
                                        }} className='font-size-12 font-wt-600 text-danger cursor mt-2'>{`Save to Inventory`}</label>
                                        : null}
                                    </div>
                                  </td>
                                </tr>
                              }
                            })}
                          </NewTablev2>
                        </div></> :
                        <div class="d-flex mx-4">
                          <NewTablev2 columns={[{ subColumns: 'No', subColumnStyle: { width: '5%' } }, { subColumns: 'Particulars', subColumnStyle: { width: '45%' } }, { subColumns: 'Reference Type', subColumnStyle: { width: '15%' } },
                          { subColumns: 'Reference Name', subColumnStyle: { width: '15%' } }, { subColumns: 'Amount', subColumnStyle: { width: '10%' } }, { subColumns: 'Action', subColumnStyle: { width: '10%' } }]} >
                            {particulars.map((i, j) => {
                              if (i === null) {
                                jj = jj + 1
                                return <tr>
                                  <td><label className="font-size-14 font-wt-400 w-100 text-break" >{`${jj + 1}`}</label></td>
                                  <td>
                                    <MultipleSelectForTable singleSelection
                                      Label={'Select'}
                                      Id={`particularName${j}`}
                                      optiondata={ledgers}
                                      onClick={() => { setRefresh(refresh + 1) }}
                                      onChange={(e) => {
                                        if (e?.[e?.length - 1]?.name === "Add New") {
                                          window.open(`/tallyMastersAccountingLedger?addNew=true`, "_blank")
                                        }
                                        else {
                                          handleChange(multiSelectEventHandler(e, `particularName${j}`, "name"))
                                        }
                                      }}
                                      value={data[`particularName${j}`] ? [data[`particularName${j}`]] : []}
                                      name={`particularName${j}`}
                                      labelKey={"name"}
                                      valKey={"name"}
                                      error={errors[`particularName${j}`]}
                                    /></td>
                                  <td>
                                    <SelectForTable selectData={[{ name: 'Agst Ref' }, { name: 'New Ref' }, { name: 'Advance' }, { name: 'On Account' }]}
                                      optionLabel={"name"} optionValue={"name"}
                                      name={`referenceType${j}`} value={data[`referenceType${j}`]}
                                      onChange={e => {
                                        setData({ ...data, [`referenceType${j}`]: e.target.value, [`referenceName${j}`]: undefined })
                                      }}
                                    />
                                  </td>
                                  <td>
                                    {data[`referenceType${j}`] === "Agst Ref" ? <MultipleSelectForTable singleSelection
                                      Label={'Select'}
                                      Id={`referenceName${j}`}
                                      optiondata={salesPurchaseVouchers}
                                      onChange={(e) => {
                                        handleChange(multiSelectEventHandler(e, `referenceName${j}`, "name"))
                                      }}
                                      value={data[`referenceName${j}`] ? [data[`referenceName${j}`]] : []}
                                      name={`referenceName${j}`}
                                      labelKey={"name"}
                                      valKey={"name"}
                                      error={errors[`referenceName${j}`]}
                                    /> : <InputForTable name={`referenceName${j}`} value={data[`referenceName${j}`]} error={errors[`referenceName${j}`]} onChange={handleChange} />
                                    }
                                  </td>
                                  <td>
                                    <InputForTable
                                      type={"number"} name={`particularAmount${j}`} value={data[`particularAmount${j}`]} error={errors[`particularAmount${j}`]} onChange={handleChange} />
                                  </td>
                                  <td>
                                    <div className="" >
                                      {(particulars.length - particulars.filter(i => {
                                        if (i === undefined) {
                                          return true
                                        }
                                      }).length) - 1 === jj ?
                                        <img
                                          onClick={async () => {
                                            let temp = particulars
                                            temp.push(null)
                                            setParticulars([...temp])
                                          }}
                                          className="cursor" src="assets/images/add_black_icon.png" /> :
                                        <img
                                          onClick={() => {
                                            let temp = particulars
                                            temp[j] = undefined
                                            setParticulars([...temp])
                                          }}
                                          className="cursor" src="assets/images/delete.png" />}
                                    </div>
                                  </td>
                                </tr>
                              }
                            })}
                          </NewTablev2>
                        </div>}</> :
                      <div class="d-flex mx-4">
                        <NewTablev2 columns={[{ subColumns: '', subColumnStyle: { width: '5%' } }, { subColumns: 'Particulars', subColumnStyle: { width: '70%' } }, { subColumns: 'Debit', subColumnStyle: { width: '10%' } },
                        { subColumns: 'Credit', subColumnStyle: { width: '10%' } }, { subColumns: 'Action', subColumnStyle: { width: '5%' } }]} >
                          {particulars.map((i, j) => {
                            if (i === null) {
                              jj = jj + 1
                              return <tr>
                                <td><label className="font-size-14 font-wt-500 w-100 text-break" >{jj / 1 == 0 ? "By" : "To"}</label></td>
                                <td>
                                  <div>
                                    <MultipleSelectForTable singleSelection
                                      Label={'Select'}
                                      Id={`particularName${j}`}
                                      optiondata={ledgers}
                                      onClick={() => { setRefresh(refresh + 1) }}
                                      onChange={(e) => {
                                        setJournalInvItems({ ...journalInvItems, [j]: [null] })
                                        if (e?.[e?.length - 1]?.name === "Add New") {
                                          window.open(`/tallyMastersAccountingLedger?addNew=true`, "_blank")
                                        }
                                        else {
                                          handleChange(multiSelectEventHandler(e, `particularName${j}`, "name"))
                                        }
                                      }}
                                      value={data[`particularName${j}`] ? [data[`particularName${j}`]] : []}
                                      name={`particularName${j}`}
                                      labelKey={"name"}
                                      valKey={"name"}
                                      error={errors[`particularName${j}`]}
                                    />
                                  </div>
                                  {data[`particularName${j}`] && journalInvItems?.[jj]?.length ? <div>
                                    <NewTablev2 columns={[{ subColumns: 'Item Name', subColumnStyle: { width: '25%' } }, { subColumns: 'Godown', subColumnStyle: { width: '10%' } },
                                    { subColumns: 'Quantity', subColumnStyle: { width: '5%' } }, { subColumns: 'Rate', subColumnStyle: { width: '5%' } }, { subColumns: 'Amount', subColumnStyle: { width: '5%' } },
                                    { subColumns: 'Action', subColumnStyle: { width: '5%' } }]} >
                                      {journalInvItems?.[jj].map((i, j) => {
                                        if (i === null) {
                                          kk = kk + 1
                                          return <tr> <td>
                                            <MultipleSelectForTable singleSelection
                                              Label={'Select'}
                                              Id={`itemName${jj}:${j}`}
                                              optiondata={items}
                                              onClick={() => { setRefresh(refresh + 1) }}
                                              onChange={(e) => {
                                                if (e?.[e?.length - 1]?.commodity_pretty_name === "Add New") {
                                                  window.open(`/manageCommodities?add=true`, "_blank")
                                                }
                                                else {
                                                  handleChange(multiSelectEventHandler(e, `itemName${jj}:${j}`, "commodity_pretty_name"))
                                                }
                                              }}
                                              value={data[`itemName${jj}:${j}`] ? [data[`itemName${jj}:${j}`]] : []}
                                              name={`itemName${jj}:${j}`}
                                              labelKey={"commodity_pretty_name"}
                                              valKey={"commodity_pretty_name"}
                                              error={errors[`itemName${jj}:${j}`]}
                                            /></td>
                                            <td>
                                              <MultipleSelectForTable singleSelection
                                                Label={'Select'}
                                                Id={`itemGodown${jj}:${j}`}
                                                optiondata={items?.filter((m, n) => { if (m.commodity_pretty_name === data[`itemName${jj}:${j}`]) { return true } })?.[0]?.procured?.filter((m, n) => {
                                                  if (m.state === 'Procured' && m.godown) { return true }
                                                }) || []}
                                                onChange={(e) => {
                                                  handleChange(multiSelectEventHandler(e, `itemGodown${jj}:${j}`, "godown"))
                                                }}
                                                value={data[`itemGodown${jj}:${j}`] ? [data[`itemGodown${jj}:${j}`]] : []}
                                                name={`itemGodown${jj}:${j}`}
                                                labelKey={"godown"}
                                                valKey={"godown"}
                                                error={errors[`itemGodown${jj}:${j}`]}
                                              /></td>
                                            <td>
                                              <InputForTable
                                                type={"number"} name={`itemQuantity${jj}:${j}`} value={data[`itemQuantity${jj}:${j}`]} error={errors[`itemQuantity${jj}:${j}`]} onChange={handleChange} />
                                            </td>
                                            <td>
                                              <InputForTable
                                                type={"number"} name={`itemRate${jj}:${j}`} value={data[`itemRate${jj}:${j}`]} error={errors[`itemRate${jj}:${j}`]} onChange={handleChange} />
                                            </td>
                                            <td>
                                              <InputForTable type={"number"} name={`itemAmt${jj}:${j}`} value={data[`itemAmt${jj}:${j}`]} error={errors[`itemAmt${jj}:${j}`]} onChange={handleChange} />
                                            </td>
                                            <td>
                                              <div className="" >
                                                {(journalInvItems?.[jj].length - journalInvItems?.[jj].filter(i => {
                                                  if (i === undefined) {
                                                    return true
                                                  }
                                                }).length) - 1 === jj ?
                                                  <img
                                                    onClick={async () => {
                                                      let temp = journalInvItems?.[jj]
                                                      temp.push(null)
                                                      setJournalInvItems({ ...temp })
                                                    }}
                                                    className="cursor" src="assets/images/add_black_icon.png" /> :
                                                  <img
                                                    onClick={() => {
                                                      let temp = journalInvItems?.[jj]
                                                      temp[j] = undefined
                                                      setJournalInvItems({ ...temp })
                                                    }}
                                                    className="cursor" src="assets/images/delete.png" />}
                                              </div>
                                            </td>
                                          </tr>
                                        }
                                      })}
                                    </NewTablev2>
                                  </div> : null}
                                </td>
                                <td>
                                  {jj / 1 == 0 ?
                                    <InputForTable
                                      type={"number"} name={`particularAmount${j}`} value={data[`particularAmount${j}`]} error={errors[`particularAmount${j}`]} onChange={handleChange} /> : null}
                                </td>
                                <td>
                                  {jj / 1 != 0 ? <InputForTable
                                    type={"number"} name={`particularAmount${j}`} value={data[`particularAmount${j}`]} error={errors[`particularAmount${j}`]} onChange={handleChange} />
                                    : null}
                                </td>
                                <td>
                                  <div className="" >
                                    {(particulars.length - particulars.filter(i => {
                                      if (i === undefined) {
                                        return true
                                      }
                                    }).length) - 1 === jj ?
                                      <img
                                        onClick={async () => {
                                          let temp = particulars
                                          temp.push(null)
                                          setParticulars([...temp])
                                        }}
                                        className="cursor" src="assets/images/add_black_icon.png" /> :
                                      <img
                                        onClick={() => {
                                          let temp = particulars
                                          temp[j] = undefined
                                          setParticulars([...temp])
                                        }}
                                        className="cursor" src="assets/images/delete.png" />}
                                  </div>
                                </td>
                              </tr>
                            }
                          })}
                        </NewTablev2>
                      </div>}


                    <div className="row d-flex px-4 pt-4">
                      <div className={data.voucherType === "Journal" ? "col-md-8" : "col-md-9"}>
                        <div className='col-6 m-0 p-0'>
                          <NewTextArea label={"Narration"}
                            name={"narration"} value={data.narration} error={errors.narration}
                            onChange={handleChange} />
                        </div>
                      </div>
                      {data.voucherType === "Journal" ? <>
                        <div className="col-md-2">
                          <div className='col-12 m-0 p-0'>
                            <InputWithSelect type={"number"} label={"Total Debit"}
                              selectData={currencyData}
                              selectName={"totalAmtCurrency"} selectValue={data.totalAmtCurrency}
                              optionLabel={"code"} optionValue={'code'}
                              name={'totalDebit'} value={data.totalDebit} error={errors.totalDebit}
                              onChange={handleChange} />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className='col-12 m-0 p-0'>
                            <InputWithSelect type={"number"} label={"Total Credit"}
                              selectData={currencyData}
                              selectName={"totalAmtCurrency"} selectValue={data.totalAmtCurrency}
                              optionLabel={"code"} optionValue={'code'}
                              name={'totalCredit'} value={data.totalCredit} error={errors.totalCredit}
                              onChange={handleChange} />
                          </div>
                        </div>
                      </> :
                        <div className="col-md-3">
                          <div className='col-12 m-0 p-0'>
                            <InputWithSelect type={"number"} label={"Total Amount"}
                              selectData={currencyData}
                              selectName={"totalAmtCurrency"} selectValue={data.totalAmtCurrency}
                              optionLabel={"code"} optionValue={'code'}
                              name={'totalAmount'} value={data.totalAmount} error={errors.totalAmount}
                              onChange={handleChange} />
                          </div>
                        </div>}
                    </div>

                    <div className='d-flex justify-content-center'>
                      <button type="button"
                        onClick={() => {
                          handleValidation()
                        }}
                        className={` new-btn w-15 py-2 px-2 text-white mt-5`}>
                        {createGroupView.viewUpdate ? "Update" : "Submit"}
                      </button>
                    </div>
                  </div>
                </> :
                <>
                  <div>
                    <NewTablev2 filterData={filterData} columns={[
                      { subColumns: 'Date' },
                      { subColumns: 'Voucher Type' },
                      { subColumns: 'Invoice/Ref No' },
                      { subColumns: 'Ledger' },
                      { subColumns: 'Narration' },
                      { subColumns: 'Amount' },
                      { subColumns: 'Action' }
                    ]} >
                      {dbData.map((item, j) => {
                        return (
                          <tr>
                            <td ><label className="font-size-13 font-wt-400 text-break" >{moment(item.createdAt).format("DD/MM/YYYY")}</label></td>
                            <td ><label className="font-size-13 font-wt-400 text-break" >{item.details?.voucherType || "-"}</label></td>
                            <td ><label className="font-size-13 font-wt-400 text-break" >{item.details?.invoiceNo || "-"}</label></td>
                            <td ><label className="font-size-13 font-wt-400 text-break" >{item.details?.selectedLedger || "-"}</label></td>
                            <td ><label className="font-size-13 font-wt-400 text-break" >{item.details?.narration || "-"}</label></td>
                            <td ><label className="font-size-13 font-wt-400 text-break" >{item.details?.totalAmount ? `${item.details?.totalAmtCurrency} ${item.details?.totalAmount}` : "-"}</label></td>
                            <td
                              // style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', display: showGridCol(filterData, wcQuotesColumn[6]["subColumns"]) }}
                              className='position-relative'>
                              <i className="fa fa-ellipsis-v cursor mt-2 ml-2"
                                onClick={() => setAction({ show: true, index: j })}
                                aria-hidden="true"></i>
                              {action.show && action.index === j ? (
                                <Action
                                  id={j}
                                  onDismiss={() => setAction({ show: false, index: j })}
                                  options={[
                                    {
                                      name: "View/Update", onClick: () => {
                                        setData({ ...item, ...item.details })
                                        if (item?.details?.invItems) {
                                          setInvItems([...item.details.invItems])
                                        }
                                        if (item?.details?.particulars) {
                                          setParticulars([...item.details.particulars])
                                        }
                                        if (item?.details?.journalInvItems) {
                                          setJournalInvItems({ ...item?.details?.journalInvItems })
                                        }
                                        toggleCreateGroupView({ show: true, viewUpdate: true })
                                      }
                                    }, {
                                      name: "Delete", onClick: async () => {
                                        setshowLoader(true)
                                        await call("POST", 'deleteTransactionVoucher', { id: item.id })
                                        setshowLoader(false)
                                        toastDisplay('Godown deleted', "success", () => {
                                          setRefresh(refresh + 1)
                                        })
                                      }
                                    }
                                  ]} />
                              ) : null}
                            </td>
                          </tr>
                        )
                      })}
                    </NewTablev2>
                  </div>
                  <Pagination perPage={filter.resultPerPage || 10} page={page} totalCount={count} onPageChange={(p) => setPage(p)} />

                </>}

            </div>
          </main>
        </div >
      </div >

    </>)

}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(Voucher)