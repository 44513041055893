// const env = "local"
const env = "dev"
// const env = "prod"

// function getTradeFinBackendUrl() {
//   if (env === "local") {
//     return "http://localhost:3004"
//   }
//   if (env === "dev") {
//     return "https://webfindev-backend.sofodev.co"
//   }
//   if (env === "prod") {
//     return "https://backend.traderebootfin.com"
//   }
// }

function getMainUrl() {
  if (env === "local") {
    return "http://localhost:3000"
  }
  if (env === "dev") {
    return "https://dev.tradereboot.com"
  }
  if (env === "prod") {
    return "https://tradereboot.com"
  }
}

function getPlatFormUrl() {
  if (env === "local") {
    return "http://localhost:3000"
  }
  if (env === "dev") {
    return "https://devalpha.app.tradereboot.com"
  }
  if (env === "prod") {
    return "https://app.tradereboot.com"
  }
}

function getPlatFormBackendUrl() {
  if (env === "local") {
    return "http://localhost:3212"
  }
  if (env === "dev") {
    return "https://devserver.tradereboot.com"
  }
  if (env === "prod") {
    return "https://server.tradereboot.com"
  }
}

function getWebsiteBackendURL() {
  if (env === "local") {
    return "http://localhost:3212"
  }
  if (env === "dev") {
    return "https://backendwebdev.sofodev.co"
  }
  if (env === "prod") {
    return "https://backend.tradereboot.com"
  }
}

function getAIServerBackendURL() {
  if (env === "local") {
    return "https://serverai.tradereboot.com"
  }
  if (env === "dev") {
    return "https://serverai.tradereboot.com"
  }
  if (env === "prod") {
    return "https://serverai.tradereboot.com"
  }
}

export const platformURL = getPlatFormUrl()
export const websiteBackendURL = getWebsiteBackendURL()
export const mainUrl = getMainUrl()
// export const tradeFinBackendUrl = getTradeFinBackendUrl()
export const googleApiKey = "AIzaSyCLERvBgyS_Jc9cDUf_KE2F-UBnUp0np9A"
export const platformBackendUrl = getPlatFormBackendUrl()

export const aiServerBackendUrl = getAIServerBackendURL()
// export const eximBankUserId = env === "prod" ? 134 : 134
export const enableStennReqDocFlow = true
// export const ZohoWidgetCode = '618527750d0cbef38d0b24b740f6e66308a7b88f4f4fb254725da05f168cf3e842033983a7442ec4217aff75a839e80d'
export const ZohoWidgetCode = '672d298b4eb1d850216ff64add560b7ed4b3960b762fb77a528d7e4c6a2ac8ac4d8394205193ea5f9e34ff2d7666ec6b'
export const environment = env
export const ONDCSUBSCRIBERID = env === "prod" ? "" : "devserver.tradereboot.com"
export const TRBackendServerIP = env === "prod" ? "52.66.170.76" : "35.154.7.134"